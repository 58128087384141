import React from "react";
import AdvantagesComponent from "../../components/AdvantagesComponent";
import CompArticles from "../../components/CompArticles";
import CompFaqs from "../../components/CompFaqs";
import CompSteps from "../../components/CompSteps";
import CompTextImageLeft from "../../components/CompTextImageLeft";
import Hero from "../../components/Hero";
import TextImageRightComponent from "../../components/TextImageRightComponent";
import { homeAdvantagesComponent } from "../../constants/dummyData";
import { SHome } from "./styles/SHome";

const Home = () => {
  return (
    <SHome>
      <Hero />
      <AdvantagesComponent {...homeAdvantagesComponent} />
      <TextImageRightComponent />
      <CompSteps />
      <CompTextImageLeft />
      <CompFaqs />
      <CompArticles />
    </SHome>
  );
};

export default Home;
