import React, { useEffect } from "react";
import image from "../../assets/images/text-image-left.png";
import { SCompTextImageLeft } from "./styles/SCompTextImageLeft";
import { SCompTextImageLeftCard } from "./styles/SCompTextImageLeftCard";
import { SCompTextImageLeftCards } from "./styles/SCompTextImageLeftCards";
import { SCompTextImageLeftCardTitle } from "./styles/SCompTextImageLeftCardTitle";
import { SCompTextImageLeftContainer } from "./styles/SCompTextImageLeftContainer";
import { SCompTextImageLeftContent } from "./styles/SCompTextImageLeftContent";
import { SCompTextImageLeftIcon } from "./styles/SCompTextImageLeftIcon";
import { SCompTextImageLeftIImg } from "./styles/SCompTextImageLeftIImg";
import { SCompTextImageLeftIImgCont } from "./styles/SCompTextImageLeftIImgCont";
import { SCompTextImageLeftLines } from "./styles/SCompTextImageLeftLines";
import { SCompTextImageLeftText } from "./styles/SCompTextImageLeftText";
import { SCompTextImageLeftTitle } from "./styles/SCompTextImageLeftTitle";
import { SCompTextImageLeftWrapper } from "./styles/SCompTextImageLeftWrapper";
import AOS from "aos";
import "aos/dist/aos.css";

const CompTextImageLeft = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);
  return (
    <SCompTextImageLeft className="comp-text-image-left" style={{ backgroundColor: "#e5f97f61" }}>
      <SCompTextImageLeftContainer>
        <SCompTextImageLeftWrapper>
          <SCompTextImageLeftContent data-aos="fade-left">
            <SCompTextImageLeftTitle>بيزي لمقدمين الخدمة !</SCompTextImageLeftTitle>
            <SCompTextImageLeftText>
              أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من
              أفراد أو شركات ، حيث يتم طلب الخدمة
            </SCompTextImageLeftText>
            <SCompTextImageLeftCards>
              <SCompTextImageLeftCard>
                <SCompTextImageLeftIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <g clip-path="url(#clip0_101_5141)">
                      <path
                        d="M21.25 2.5C22.568 2.5 23.6477 3.51985 23.7431 4.81342L23.75 5V25C23.75 26.318 22.7302 27.3977 21.4366 27.4931L21.25 27.5H8.75C7.43205 27.5 6.35229 26.4802 6.25686 25.1866L6.25 25V5C6.25 3.68205 7.26985 2.60229 8.56342 2.50686L8.75 2.5H21.25ZM15.625 20H14.375C14.0299 20 13.75 20.2799 13.75 20.625V21.875C13.75 22.2201 14.0299 22.5 14.375 22.5H15.625C15.9701 22.5 16.25 22.2201 16.25 21.875V20.625C16.25 20.2799 15.9701 20 15.625 20Z"
                        fill="#E5F97F"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_101_5141">
                        <rect width="30" height="30" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SCompTextImageLeftIcon>
                <SCompTextImageLeftCardTitle>سهولة طلب الخدمة</SCompTextImageLeftCardTitle>
              </SCompTextImageLeftCard>
              <SCompTextImageLeftCard>
                <SCompTextImageLeftIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="#0A1730">
                    <g clip-path="url(#clip0_101_5153)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5396 1.69984C17.3294 0.752118 18.869 1.39999 18.7435 2.62724L17.8616 11.2501H25C26.0597 11.2501 26.6388 12.4861 25.9603 13.3002L13.4603 28.3002C12.6705 29.248 11.1309 28.6001 11.2564 27.3729L12.1383 18.7501H4.99995C3.94015 18.7501 3.3612 17.514 4.03968 16.6999L16.5396 1.69984Z"
                        fill="#E5F97F"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_101_5153">
                        <rect width="30" height="30" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SCompTextImageLeftIcon>
                <SCompTextImageLeftCardTitle>سرعة الإستجابة</SCompTextImageLeftCardTitle>
              </SCompTextImageLeftCard>
              <SCompTextImageLeftCard>
                <SCompTextImageLeftIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <g clip-path="url(#clip0_101_5117)">
                      <path
                        d="M29.75 22.8692V24.7917C29.75 25.945 29.2098 26.9321 28.4613 27.7063C27.7192 28.4739 26.7139 29.1003 25.5817 29.5956C23.3129 30.5882 20.2777 31.1667 17 31.1667C13.7223 31.1667 10.6871 30.5882 8.4183 29.5956C7.28602 29.1003 6.28076 28.4739 5.53878 27.7063C4.8526 26.9966 4.34148 26.108 4.26106 25.0765L4.25 24.7917V22.8691C4.9094 23.2432 5.61339 23.5745 6.36629 23.8533C9.24209 24.9178 13.0033 25.5123 17.0001 25.5123C20.997 25.5123 24.7581 24.9178 27.6339 23.8533C28.1985 23.6442 28.7357 23.4057 29.2471 23.1416L29.75 22.8692ZM4.25 15.0774C4.9094 15.4516 5.61339 15.7828 6.36629 16.0616C9.24209 17.1261 13.0033 17.7207 17.0001 17.7207C20.997 17.7207 24.7581 17.1261 27.6339 16.0616C28.3867 15.7828 29.0907 15.4517 29.75 15.0776V19.4759C28.8307 20.181 27.8058 20.7683 26.6503 21.1962C24.1635 22.1167 20.7508 22.679 17.0001 22.679C13.2495 22.679 9.83667 22.1167 7.34994 21.1962C6.19436 20.7683 5.16933 20.1808 4.25 19.4758V15.0774ZM17 4.25C20.2777 4.25 23.3129 4.82845 25.5817 5.82104C26.7139 6.31641 27.7192 6.94284 28.4613 7.71032C29.1474 8.42009 29.6585 9.30864 29.7389 10.3402L29.75 10.625V11.6843C28.8307 12.3893 27.8058 12.9767 26.6503 13.4044C24.1635 14.325 20.7508 14.8873 17.0001 14.8873C13.2495 14.8873 9.83667 14.325 7.34994 13.4044C6.35944 13.0378 5.46486 12.5538 4.65054 11.979L4.25 11.6841V10.625C4.25 9.47168 4.79022 8.48462 5.53878 7.71032C6.28076 6.94284 7.28602 6.31641 8.4183 5.82104C10.6871 4.82845 13.7223 4.25 17 4.25Z"
                        fill="#E5F97F"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_101_5117">
                        <rect width="34" height="34" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SCompTextImageLeftIcon>
                <SCompTextImageLeftCardTitle>أفضل الاسعار</SCompTextImageLeftCardTitle>
              </SCompTextImageLeftCard>
              <SCompTextImageLeftCard>
                <SCompTextImageLeftIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <g clip-path="url(#clip0_101_5129)">
                      <path
                        d="M15 2.5C20.5229 2.5 25 6.97715 25 12.5C25 15.658 23.5361 18.4741 21.25 20.3067V26.0696C21.25 27.0296 20.2911 27.6941 19.3923 27.3571L15 25.71L10.6078 27.3571C9.70889 27.6941 8.75 27.0296 8.75 26.0696V20.3067C6.46385 18.4741 5 15.658 5 12.5C5 6.97715 9.47715 2.5 15 2.5ZM15 5C10.8579 5 7.5 8.35786 7.5 12.5C7.5 16.6421 10.8579 20 15 20C19.1421 20 22.5 16.6421 22.5 12.5C22.5 8.35786 19.1421 5 15 5ZM15 7.5C17.7614 7.5 20 9.73857 20 12.5C20 15.2614 17.7614 17.5 15 17.5C12.2386 17.5 10 15.2614 10 12.5C10 9.73857 12.2386 7.5 15 7.5Z"
                        fill="#E5F97F"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_101_5129">
                        <rect width="30" height="30" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SCompTextImageLeftIcon>
                <SCompTextImageLeftCardTitle>أفضل خدمة</SCompTextImageLeftCardTitle>
              </SCompTextImageLeftCard>
            </SCompTextImageLeftCards>
          </SCompTextImageLeftContent>
          <SCompTextImageLeftIImgCont>
            <SCompTextImageLeftLines>
              <svg width="386" height="495" viewBox="0 0 386 495" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M314.523 282.658L-16.0975 315.145" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
                <path d="M261.777 335.925L-27.1698 363.745" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
                <path d="M244.617 242.588L0.119797 266.613" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
                <path d="M356.319 183.528L-50.598 223.512" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
              </svg>
            </SCompTextImageLeftLines>
            <SCompTextImageLeftIImg data-aos="fade-up">
              <img src={image} alt="" />
            </SCompTextImageLeftIImg>
          </SCompTextImageLeftIImgCont>
        </SCompTextImageLeftWrapper>
      </SCompTextImageLeftContainer>
    </SCompTextImageLeft>
  );
};

export default CompTextImageLeft;
