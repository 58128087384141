import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SCompArticles } from "./styles/SCompArticles";
import { SCompArticlesBtn } from "./styles/SCompArticlesBtn";
import { SCompArticlesCard } from "./styles/SCompArticlesCard";
import { SCompArticlesCardBtn } from "./styles/SCompArticlesCardBtn";
import { SCompArticlesCardContent } from "./styles/SCompArticlesCardContent";
import { SCompArticlesCardImg } from "./styles/SCompArticlesCardImg";
import { SCompArticlesCardImgCont } from "./styles/SCompArticlesCardImgCont";
import { SCompArticlesCards } from "./styles/SCompArticlesCards";
import { SCompArticlesCardsFirstCol } from "./styles/SCompArticlesCardsFirstCol";
import { SCompArticlesCardsSecCol } from "./styles/SCompArticlesCardsSecCol";
import { SCompArticlesCardText } from "./styles/SCompArticlesCardText";
import { SCompArticlesCardTitle } from "./styles/SCompArticlesCardTitle";
import { SCompArticlesTitle } from "./styles/SCompArticlesTitle";
import { SCompArticlesTitleAndBtn } from "./styles/SCompArticlesTitleAndBtn";
import { SSCompArticlesContainer } from "./styles/SSCompArticlesContainer";
import AOS from "aos";
import "aos/dist/aos.css";

const CompArticles = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("https://blog.busyapp.com.sa/wp-json/wp/v2/posts?categories=9");
        const data = await response.json();

        // Fetch featured images separately
        const postsWithImages = await Promise.all(
          data.map(async (post) => {
            if (post.featured_media) {
              const mediaResponse = await fetch(
                `https://blog.busyapp.com.sa/wp-json/wp/v2/media/${post.featured_media}`,
              );
              const mediaData = await mediaResponse.json();
              return {
                ...post,
                featured_image: mediaData.source_url,
              };
            }
            return post;
          }),
        );

        setPosts(postsWithImages);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPosts();
  }, []);
  if (!posts?.length) return <></>;
  return (
    <SCompArticles className="comp-articles">
      <SSCompArticlesContainer>
        <SCompArticlesTitleAndBtn>
          <SCompArticlesTitle>أحدث الأخبار و المقالات</SCompArticlesTitle>
          <Link to="/blogs">
            <SCompArticlesBtn>عرض المزيد</SCompArticlesBtn>
          </Link>
        </SCompArticlesTitleAndBtn>
        <SCompArticlesCards>
          <SCompArticlesCardsFirstCol data-aos="fade-left">
            {posts[1] && (
              <SCompArticlesCard>
                <SCompArticlesCardImgCont>
                  <SCompArticlesCardImg>
                    <img src={posts[1].featured_image} alt={posts[1].title.rendered} />
                  </SCompArticlesCardImg>
                </SCompArticlesCardImgCont>
                <SCompArticlesCardContent>
                  <SCompArticlesCardTitle>{posts[1].title.rendered}</SCompArticlesCardTitle>
                  <SCompArticlesCardText>
                    <div dangerouslySetInnerHTML={{ __html: posts[1].excerpt.rendered }} />
                  </SCompArticlesCardText>
                  <Link to={`/blogs/${posts[1].id}`}>
                    <SCompArticlesCardBtn>أقرأ المزيد</SCompArticlesCardBtn>
                  </Link>
                </SCompArticlesCardContent>
              </SCompArticlesCard>
            )}
            {posts[0] && (
              <SCompArticlesCard>
                <SCompArticlesCardImgCont>
                  <SCompArticlesCardImg>
                    <img src={posts[0].featured_image} alt={posts[0].title.rendered} />
                  </SCompArticlesCardImg>
                </SCompArticlesCardImgCont>
                <SCompArticlesCardContent>
                  <SCompArticlesCardTitle>{posts[0].title.rendered}</SCompArticlesCardTitle>
                  <SCompArticlesCardText>
                    <div dangerouslySetInnerHTML={{ __html: posts[0].excerpt.rendered }} />
                  </SCompArticlesCardText>
                  <Link to={`/blogs/${posts[0].id}`}>
                    <SCompArticlesCardBtn>أقرأ المزيد</SCompArticlesCardBtn>
                  </Link>
                </SCompArticlesCardContent>
              </SCompArticlesCard>
            )}
          </SCompArticlesCardsFirstCol>
          <SCompArticlesCardsSecCol>
            {posts[2] && (
              <SCompArticlesCard isFullHeight data-aos="fade-right">
                <SCompArticlesCardImgCont isFullHeight>
                  <SCompArticlesCardImg isFullHeight>
                    <img src={posts[2].featured_image} alt={posts[2].title.rendered} />
                  </SCompArticlesCardImg>
                </SCompArticlesCardImgCont>
                <SCompArticlesCardContent isFullHeight>
                  <SCompArticlesCardTitle>{posts[2].title.rendered}</SCompArticlesCardTitle>
                  <SCompArticlesCardText>
                    <div dangerouslySetInnerHTML={{ __html: posts[2].excerpt.rendered }} />
                  </SCompArticlesCardText>
                  <Link to={`/blogs/${posts[2].id}`}>
                    <SCompArticlesCardBtn>أقرأ المزيد</SCompArticlesCardBtn>
                  </Link>
                </SCompArticlesCardContent>
              </SCompArticlesCard>
            )}
          </SCompArticlesCardsSecCol>
        </SCompArticlesCards>
      </SSCompArticlesContainer>
    </SCompArticles>
  );
};

export default CompArticles;
