import React, { useEffect } from "react";
import { SCompTextImageRight } from "./styles/SCompTextImageRight";
import { SCompTextImageRightCard } from "./styles/SCompTextImageRightCard";
import { SCompTextImageRightCards } from "./styles/SCompTextImageRightCards";
import { SCompTextImageRightCardTitle } from "./styles/SCompTextImageRightCardTitle";
import { SCompTextImageRightContainer } from "./styles/SCompTextImageRightContainer";
import { SCompTextImageRightContent } from "./styles/SCompTextImageRightContent";
import { SCompTextImageRightIcon } from "./styles/SCompTextImageRightIcon";
import { SCompTextImageRightIImg } from "./styles/SCompTextImageRightIImg";
import { SCompTextImageRightIImgCont } from "./styles/SCompTextImageRightIImgCont";
import { SCompTextImageRightLines } from "./styles/SCompTextImageRightLines";
import { SCompTextImageRightText } from "./styles/SCompTextImageRightText";
import { SCompTextImageRightTitle } from "./styles/SCompTextImageRightTitle";
import { SCompTextImageRightWrapper } from "./styles/SCompTextImageRightWrapper";
import image from "../../assets/images/text-image-right.png";
import AOS from "aos";
import "aos/dist/aos.css";

const TextImageRightComponent = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);
  return (
    <SCompTextImageRight className="comp-text-image-right">
      <SCompTextImageRightContainer>
        <SCompTextImageRightWrapper>
          <SCompTextImageRightIImgCont>
            <SCompTextImageRightLines>
              <svg width="341" height="495" viewBox="0 0 341 495" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M71.4771 282.658L402.097 315.145" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
                <path d="M124.223 335.925L413.169 363.745" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
                <path d="M141.383 242.588L385.88 266.613" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
                <path d="M29.6807 183.528L436.598 223.513" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
              </svg>
            </SCompTextImageRightLines>
            <SCompTextImageRightIImg data-aos="fade-left">
              <img src={image} alt="" />
            </SCompTextImageRightIImg>
          </SCompTextImageRightIImgCont>
          <SCompTextImageRightContent data-aos="fade-up">
            <SCompTextImageRightTitle>بيزي للباحثين عن عمل !</SCompTextImageRightTitle>
            <SCompTextImageRightText>
              أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من
              أفراد أو شركات ، حيث يتم طلب الخدمة
            </SCompTextImageRightText>
            <SCompTextImageRightCards>
              <SCompTextImageRightCard>
                <SCompTextImageRightIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <g clip-path="url(#clip0_101_5165)">
                      <path
                        d="M21.25 2.5C22.568 2.5 23.6477 3.51985 23.7431 4.81342L23.75 5V25C23.75 26.318 22.7302 27.3977 21.4366 27.4931L21.25 27.5H8.75C7.43205 27.5 6.35229 26.4802 6.25686 25.1866L6.25 25V5C6.25 3.68205 7.26985 2.60229 8.56342 2.50686L8.75 2.5H21.25ZM15.625 20H14.375C14.0299 20 13.75 20.2799 13.75 20.625V21.875C13.75 22.2201 14.0299 22.5 14.375 22.5H15.625C15.9701 22.5 16.25 22.2201 16.25 21.875V20.625C16.25 20.2799 15.9701 20 15.625 20Z"
                        fill="#0A1730"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_101_5165">
                        <rect width="30" height="30" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SCompTextImageRightIcon>
                <SCompTextImageRightCardTitle>سهولة التقديم</SCompTextImageRightCardTitle>
              </SCompTextImageRightCard>
              <SCompTextImageRightCard>
                <SCompTextImageRightIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <g clip-path="url(#clip0_101_5159)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5396 1.69984C17.3294 0.752118 18.869 1.39999 18.7435 2.62724L17.8616 11.2501H25C26.0597 11.2501 26.6388 12.4861 25.9603 13.3002L13.4603 28.3002C12.6705 29.248 11.1309 28.6001 11.2564 27.3729L12.1383 18.7501H4.99995C3.94015 18.7501 3.3612 17.514 4.03968 16.6999L16.5396 1.69984Z"
                        fill="#0A1730"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_101_5159">
                        <rect width="30" height="30" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SCompTextImageRightIcon>
                <SCompTextImageRightCardTitle>سرعة التقديم</SCompTextImageRightCardTitle>
              </SCompTextImageRightCard>
              <SCompTextImageRightCard>
                <SCompTextImageRightIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <g clip-path="url(#clip0_101_5111)">
                      <path
                        d="M29.75 22.8692V24.7917C29.75 25.945 29.2098 26.9321 28.4613 27.7063C27.7192 28.4739 26.7139 29.1003 25.5817 29.5956C23.3129 30.5882 20.2777 31.1667 17 31.1667C13.7223 31.1667 10.6871 30.5882 8.4183 29.5956C7.28602 29.1003 6.28076 28.4739 5.53878 27.7063C4.8526 26.9966 4.34148 26.108 4.26106 25.0765L4.25 24.7917V22.8691C4.9094 23.2432 5.61339 23.5745 6.36629 23.8533C9.24209 24.9178 13.0033 25.5123 17.0001 25.5123C20.997 25.5123 24.7581 24.9178 27.6339 23.8533C28.1985 23.6442 28.7357 23.4057 29.2471 23.1416L29.75 22.8692ZM4.25 15.0774C4.9094 15.4516 5.61339 15.7828 6.36629 16.0616C9.24209 17.1261 13.0033 17.7207 17.0001 17.7207C20.997 17.7207 24.7581 17.1261 27.6339 16.0616C28.3867 15.7828 29.0907 15.4517 29.75 15.0776V19.4759C28.8307 20.181 27.8058 20.7683 26.6503 21.1962C24.1635 22.1167 20.7508 22.679 17.0001 22.679C13.2495 22.679 9.83667 22.1167 7.34994 21.1962C6.19436 20.7683 5.16933 20.1808 4.25 19.4758V15.0774ZM17 4.25C20.2777 4.25 23.3129 4.82845 25.5817 5.82104C26.7139 6.31641 27.7192 6.94284 28.4613 7.71032C29.1474 8.42009 29.6585 9.30864 29.7389 10.3402L29.75 10.625V11.6843C28.8307 12.3893 27.8058 12.9767 26.6503 13.4044C24.1635 14.325 20.7508 14.8873 17.0001 14.8873C13.2495 14.8873 9.83667 14.325 7.34994 13.4044C6.35944 13.0378 5.46486 12.5538 4.65054 11.979L4.25 11.6841V10.625C4.25 9.47168 4.79022 8.48462 5.53878 7.71032C6.28076 6.94284 7.28602 6.31641 8.4183 5.82104C10.6871 4.82845 13.7223 4.25 17 4.25Z"
                        fill="#0A1730"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_101_5111">
                        <rect width="34" height="34" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SCompTextImageRightIcon>
                <SCompTextImageRightCardTitle>أطلب السعر المناسب</SCompTextImageRightCardTitle>
              </SCompTextImageRightCard>
              <SCompTextImageRightCard>
                <SCompTextImageRightIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <g clip-path="url(#clip0_101_5107)">
                      <path
                        d="M30.7456 5.62538C30.45 5.05488 29.9576 4.61087 29.3597 4.37562C25.3753 2.83034 21.4573 1.35546 17.6377 0.09706C17.2497 -0.0323533 16.8303 -0.0323533 16.4423 0.09706C12.6233 1.35214 8.70602 2.82436 4.72231 4.36765C4.19727 4.57646 3.7525 4.94726 3.45263 5.42616C3.47454 5.42948 2.63317 8.05585 2.4904 13.8598C2.40208 19.6564 3.12458 21.3955 3.10067 21.3975C3.49114 22.6719 4.22294 23.9694 5.27216 25.2883C6.44423 26.7612 8.02802 28.2825 9.94051 29.7787C13.1081 32.2643 16.0718 33.7498 16.196 33.8022C16.4594 33.9328 16.7493 34.0008 17.0433 34.0008C17.3373 34.0008 17.6273 33.9328 17.8907 33.8022C18.0155 33.7498 20.9825 32.2596 24.1481 29.7747C26.0613 28.2779 27.6457 26.7565 28.8185 25.2843C29.9421 23.8718 30.7018 22.4859 31.069 21.1246C31.0544 21.1246 31.5856 18.9923 31.5119 14.1101C31.4183 9.23058 30.7383 5.62804 30.7456 5.62538Z"
                        fill="#0A1730"
                      />
                      <path
                        d="M25.3672 13.7013C22.5562 16.5959 19.6669 19.6267 16.8353 22.6183C16.6055 22.8623 16.3281 23.0567 16.0203 23.1895C15.7126 23.3223 15.3809 23.3908 15.0457 23.3906H15.0344C14.6989 23.3868 14.3675 23.3161 14.0596 23.1827C13.7518 23.0493 13.4736 22.8559 13.2414 22.6137C11.7253 21.0445 10.1987 19.4786 8.68194 17.9426C7.71175 16.9625 7.74163 15.4192 8.74436 14.461C9.7471 13.5027 11.3276 13.4974 12.2865 14.4816C13.1878 15.4046 14.0925 16.3343 15.0005 17.2706C17.2503 14.9105 19.5088 12.5319 21.7075 10.2209C22.6419 9.23745 24.2037 9.2494 25.2204 10.2209C26.2371 11.1924 26.3088 12.7311 25.3672 13.7013Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_101_5107">
                        <rect width="34" height="34" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SCompTextImageRightIcon>
                <SCompTextImageRightCardTitle>الأمان</SCompTextImageRightCardTitle>
              </SCompTextImageRightCard>
            </SCompTextImageRightCards>
          </SCompTextImageRightContent>
        </SCompTextImageRightWrapper>
      </SCompTextImageRightContainer>
    </SCompTextImageRight>
  );
};

export default TextImageRightComponent;
