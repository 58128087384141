import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  margin-top: 60px;
`;

const Medium = css``;

const Large = css`
  margin-top: 0;
  width: 47.15%;
`;

const XLarge = css`
`;

const XXLarge = css``;

export const SCompTextImageRightContent = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
