import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  width: 100%;
  position: relative;
`;

const Medium = css``;

const Large = css`
  width: 41.92%;
  flex-shrink: 0;
`;

const XLarge = css`
`;

const XXLarge = css``;

export const SCompTextImageRightIImgCont = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
