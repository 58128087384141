import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -32px;
  max-width: 100%;
  left: 0;
  
  svg {
    max-width: 100%;
  }
`;

const Medium = css``;

const Large = css`
  margin-left: -90px;
`;

const XLarge = css`
  margin-left: -111px;
`;

const XXLarge = css``;

export const SCompTextImageLeftLines = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
